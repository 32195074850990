import React, { Component, Fragment } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import Loadable from 'react-loadable';
import Alert from 'react-s-alert';
import ClearCache from 'react-clear-cache';
import 'react-s-alert/dist/s-alert-default.css';
import 'react-s-alert/dist/s-alert-css-effects/slide.css';
// import './scss/style.scss';
// import './components/Footer/footer.sccs';
import './App.scss';

const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

// Containers
const DefaultLayout = Loadable({
  loader: () => import('./containers/DefaultLayout'),
  loading
});

// Pages
const Login = Loadable({
  loader: () => import('./views/Pages/Login/Login'),
  loading
});

const RegisterToEvent = Loadable({
  loader: () => import('./views/Pages/RegisterToEvent/RegisterToEvent'),
  loading
});

const Remind = Loadable({
  loader: () => import('./views/Pages/Remind/Remind'),
  loading
});

const Events = Loadable({
  loader: () => import('./views/Pages/Events/Events'),
  loading
});

const Event = Loadable({
  loader: () => import('./views/Pages/Events/Event'),
  loading
});

const Register = Loadable({
  loader: () => import('./views/Pages/Register/Register'),
  loading
});

const SuccessRegister = Loadable({
  loader: () => import('./views/Pages/SuccessRegister/SuccessRegister'),
  loading
});

const CancelRegister = Loadable({
  loader: () => import('./views/Pages/CancelRegister/CancelRegister'),
  loading
});

const Page404 = Loadable({
  loader: () => import('./views/Pages/Page404/Page404'),
  loading
});

const Page500 = Loadable({
  loader: () => import('./views/Pages/Page500/Page500'),
  loading
});

const SiteUnavailable = Loadable({
  loader: () => import('./views/Pages/SiteUnavailable/SiteUnavailable'),
  loading
});

const NewPass = Loadable({
  loader: () => import('./views/Pages/NewPass/NewPass'),
  loading
});

class App extends Component {

  render() {
    return (
      <ClearCache auto={true}>
        {() => {
          return (
            < Fragment >
              <HashRouter>
                <Switch>
                  <Route exact path="/login" name="Login Page" component={Login} />
                  <Route exact path="/register_to_event/:id" name="RegisterToEvent Page" component={RegisterToEvent} />
                  <Route exact path="/remind" name="Remind Page" component={Remind} />
                  <Route exact path="/new_pass/:token" name="NewPass Page" component={NewPass} />
                  <Route exact path="/events" name="Events Page" component={Events} />
                  <Route exact path="/events/:id" name="Events Page" component={Event} />
                  <Route exact path="/register/:id" name="Register Page" component={Register} />
                  <Route exact path="/success" name="SuccessRegister Page" component={SuccessRegister} />
                  <Route exact path="/cancel" name="CancelRegister Page" component={CancelRegister} />
                  <Route exact path="/site_unavailable" name="Site unavailable" component={SiteUnavailable} />
                  <Route exact path="/404" name="Page 404" component={Page404} />
                  <Route exact path="/500" name="Page 500" component={Page500} />
                  <Route path="/" name="Home" component={DefaultLayout} />
                </Switch>
              </HashRouter>
              <Alert
                stack={{ limit: 5 }}
                position='top-right'
                timeout={5000}
                effect='slide'
                offset={100}
              />
            </Fragment>
          );
        }
        }
      </ClearCache >
    );
  }
}

export default App;
